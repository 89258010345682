import ReactMapboxGl, { Layer, Feature, Marker } from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MAPBOXACCESSTOKEN } from "../../utils/APPADATAURLS";
import { useEffect, useRef } from "react";
import { useUserLocation } from "../../hooks";
import MapBoxAddLine from "./MapBoxAddLine";

import mapboxgl from 'mapbox-gl';

// // ES5
// var ReactMapboxGl = require('react-mapbox-gl');
// var Layer = ReactMapboxGl.Layer;
// var Feature = ReactMapboxGl.Feature;
// require('mapbox-gl/dist/mapbox-gl.css');

const Map = ReactMapboxGl({
  accessToken: MAPBOXACCESSTOKEN,
});

const MapBoxMap = () => {
  const location = useUserLocation();
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  useEffect(()=>{
    mapboxgl.accessToken = MAPBOXACCESSTOKEN;
    mapRef.current = new mapboxgl.Map({
      container:mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [location.coordinates.longitude, location.coordinates.latitude],
      zoom: 14,
    });

    let popup = new mapboxgl.Popup()
      .setText("Me")
      .addTo(mapRef.current)
   new mapboxgl.Marker({color:"var(--app-color)",label:"Me",title:"Me"})
    .setLngLat([location.coordinates.longitude, location.coordinates.latitude])
    .addTo(mapRef.current)
    .setPopup(popup);

  },[]);
  return (
    <>
    <div
      style={{ height: "100%" }}
      ref={mapContainerRef}
      className="map-container"
    ></div>

      {/* <MapBoxAddLine/> */}

      {/* <Map
        style="mapbox://styles/mapbox/streets-v9"
        containerStyle={{
          height: "100vh",
          width: "100vw",
        }}
        center={[location.coordinates.longitude, location.coordinates.latitude]}
      >
        <Layer type="symbol" id="marker" layout={{ "icon-image": "marker-20" }}>
          <Feature
            coordinates={[
              location.coordinates.longitude,
              location.coordinates.latitude,
            ]}
          />
        </Layer>
      </Map> */}
    </>
  );
};

export default MapBoxMap;
