import React, { useEffect, useState } from "react";

const useUserLocation = () => {
  const [location, setLocation] = useState({
    coordinates: {
      latitude: null,
      longitude: null,
    },
    data: null,
    error:{
      state:false,
      message:""
    }
  });
  const fetchLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        
        setLocation({
          coordinates: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
          data: position.coords,

        });
      },
      (error) => {
        setLocation({...location,error:{state:true,message:error.message}})
      },
      {
        enableHighAccuracy: false,
        timeout: 15000,
      }
    );
  };

  useEffect(() => {
    fetchLocation();
  }, []);
  return { ...location, fetchLocation };
};

export default useUserLocation;
