// import React, { useCallback, useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { MapContainer, Marker, Popup, TileLayer, Tooltip } from "react-leaflet";
// import { FaMapPin } from "react-icons/fa";
// import iconMarker from "leaflet/dist/images/marker-icon.png";
// import L from "leaflet";
// import "leaflet/dist/leaflet.css";
// import { Header, SmallScreenHeader } from "../../components";
// import "./addresses.css";
// import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
// import { GOOGLEMAPSAPIKEY } from "../../utils/APPADATAURLS";
// const icon = L.icon({
//   iconUrl: iconMarker,
// });

// const mapKey = process.env.GOOGLEMAPSAPIKEY;

// const MapPage = () => {
//   const params = useParams();
//   const latitude = parseFloat(params.latitude);
//   const longitude = parseFloat(params.longitude);

//   console.log(latitude);
//   const { isLoaded } = useJsApiLoader({
//     id: "google-map-script",
//     googleMapsApiKey: GOOGLEMAPSAPIKEY,
//   });

//   const [map, setMap] = useState(null);
//   const [directionsService, setDirectionsService] = useState(null);
//   const [directionsDisplay, setDirectionsDisplay] = useState(null);
//   const [isUpdated, setIsUpdated] = useState(false);
//   let [isDisplayed, setIsDisplayed] = useState(false);
//   useEffect(() => {
//     console.log(mapKey, "sadfasfsdgsfd cdzsfdsf");
//     setDirectionsDisplay(null);
//     if (map) {
//       setDirectionsService(new window.google.maps.DirectionsService());
//       setDirectionsDisplay(new window.google.maps.DirectionsRenderer());
//     }

//     if (directionsDisplay) {
//       setIsDisplayed(true);
//       directionsDisplay.setMap(map);
//       directionsDisplay.setOptions({ suppressMarkers: true });
//       //showDirection(delivery.results.products[0].latitude,delivery.results.products[0].longitude);
//       //setIsUpdated(!isUpdated);
//     }
//   }, [map, isUpdated]);

//   return (
//     <div className="body">
//       <Header />
//       <div className="addresses-wrapper">
//         <SmallScreenHeader title={"Location"} />
//         <div className="map">
//         {isLoaded && latitude ? (
//           <>
//             <GoogleMap
//               mapContainerStyle={{
//                 width: "100%",
//                 height: "100%",
//               }}
//               zoom={12}
//               // center={{lat:latitude,lng:longitude}}
//               center={{ lat: latitude, lng: longitude }}
//               onLoad={(map) => {
//                 const bounds = new window.google.maps.LatLngBounds({
//                   lat: latitude,
//                   lng: longitude,
//                 });
//                 //map.fitBounds(bounds);
//                 setMap(map);
//                 setIsUpdated(!isUpdated);
//               }}
//               onUnmount={(map) => {
//                 setMap(null);
//               }}
//             >
//               <MarkerF
//                 onClick={(e) => console.log(e)}
//                 position={{ lat: latitude, lng: longitude }}
//                 title="Me"
//                 label={"Me"}
                
//               />
//             </GoogleMap>
//           </>
//         ) : (
//           <MapContainer
//             center={[latitude, longitude]}
//             zoom={13}
//             scrollWheelZoom={false}
//             style={{ width: "100%", height: "100%", zIndex: 1 }}
//           >
//             <TileLayer
//               attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//               url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//             />
//             <Marker position={[latitude, longitude]} icon={icon} key={"User"}>
//               <Tooltip>My selected location</Tooltip>
//               <Popup>My location</Popup>
//             </Marker>
//           </MapContainer>
//         )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MapPage;


import ReactMapboxGl, { Layer, Feature, Marker } from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MAPBOXACCESSTOKEN } from "../../utils/APPADATAURLS";
import { useEffect, useRef } from "react";
import { useUserLocation } from "../../hooks";
import MapBoxAddLine from "./MapBoxAddLine";

import mapboxgl from 'mapbox-gl';
import { useParams } from "react-router-dom";

// // ES5
// var ReactMapboxGl = require('react-mapbox-gl');
// var Layer = ReactMapboxGl.Layer;
// var Feature = ReactMapboxGl.Feature;
// require('mapbox-gl/dist/mapbox-gl.css');

const Map = ReactMapboxGl({
  accessToken: MAPBOXACCESSTOKEN,
});

const MapPage = () => {
  const {longitude,latitude,info} = useParams();
  
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(()=>{
    mapboxgl.accessToken = MAPBOXACCESSTOKEN;
    mapRef.current = new mapboxgl.Map({
      container:mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [longitude, latitude],
      zoom: 16,
    });

    let popup = new mapboxgl.Popup()
      .setText(info??"My location")
      .addTo(mapRef.current)
   new mapboxgl.Marker({color:"var(--app-color)"})
    .setLngLat([longitude, latitude])
    .addTo(mapRef.current)
    .setPopup(popup);

  },[]);
  return (
    <>
    <div
      style={{ height: "100%" }}
      ref={mapContainerRef}
      className="map-container"
    ></div>

      {/* <MapBoxAddLine/> */}

      {/* <Map
        style="mapbox://styles/mapbox/streets-v9"
        containerStyle={{
          height: "100vh",
          width: "100vw",
        }}
        center={[location.coordinates.longitude, location.coordinates.latitude]}
      >
        <Layer type="symbol" id="marker" layout={{ "icon-image": "marker-20" }}>
          <Feature
            coordinates={[
              location.coordinates.longitude,
              location.coordinates.latitude,
            ]}
          />
        </Layer>
      </Map> */}
    </>
  );
};

export default MapPage;
