import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaCar } from "react-icons/fa";
import styles from "./index.module.css";
import { APPDATAURLS, BASEURL } from "../../utils/APPADATAURLS";
import { HomeCategoriesLoading } from "../Loading";
import HeaderHero from "../HeaderHero";
const CategoriesBanner = ({ setIvisible }) => {
  const [categories, setCategories] = useState({
    data: [],
    loading: false,
    error: false,
    message: "",
  });

  const [imageLoaded, setImageLoaded] = useState(false);

  const fetchCategories = () => {
    setCategories({
      data: [],
      loading: true,
      error: false,
      message: "",
    });
    fetch(APPDATAURLS.categories, {
      method: "get",
    })
      .then((req) => req.json())
      .then((response) => {
        setCategories({
          data: response.data,
          loading: false,
          error: response.error,
          message: response.message,
        });
      })
      .catch((e) => {
        console.log(e);
        setCategories({
          data: [],
          loading: false,
          error: true,
          message: "An error occurred. Try again later",
        });
      });
  };
  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <div className={styles.holder}>
      <HeaderHero heading={"Categories"} viewAll={true} toUrl={"/shop"} subTitle={"shop by category"}/>
      <div className={styles.category_banner}>
        {categories.error ? (
          <div></div>
        ) : categories.loading ? (
          <HomeCategoriesLoading />
        ) : (
          categories.data.map((category, index) => {
            return (
              <Link
                onClick={() => {}}
                key={index}
                to={`/shop/${category.categoryName}/${category.id}`}
                className={styles.category_link}
              >
                <img
                  onLoad={() => setImageLoaded(true)}
                  onError={() => setImageLoaded(false)}
                  style={{
                    display: imageLoaded ? "flex" : "flex",
                  }}
                  className={styles.star}
                  src={`${BASEURL}/${category.categoryImage}`}
                />
                <img
                  style={{
                    display: imageLoaded ? "none" : "none",
                  }}
                  className={styles.star}
                  src={"/assets/images/savo_logo.jpg"}
                />
                <p className={styles.p}>{category.categoryName}</p>
              </Link>
            );
          })
        )}
      </div>
    </div>
  );
};

export default CategoriesBanner;
